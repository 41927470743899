export default {
	//中文繁体
	tabbar: ['首頁', '訂單', '搶單', '客服', '我的'],
	lxb:{
		name: '利息寶' ,
		suc : '提交成功' ,
		tab :[ '總資產' , '餘額寶' , '總收益' , '昨日收益' ],
		zrje : '轉入金額' ,
		yjsr : '預計收入' ,
		zdxz : '最低數量' ,
		tip1 : '請輸入轉入金額' ,
		btn1 : '轉入' ,
		btn2 : '轉入記錄' ,
		day : '天' ,
		tip2 : '您確定要取出嗎？ ' ,
		tip : '提示' ,
		crsj : '存入時間' ,
		crje : '存入金額' ,
		qc : '取出' ,
		confirm : '確定' ,
		cancel : '取消' ,
		success : '操作成功' ,
		sign : '簽到' ,
		signTip : '您還沒簽到，現在去簽到嗎?' ,
		qdjl : '簽到獎勵',
		lxbzr:'利息寶轉入',
		lxbfh:'利息寶返還',
		lxbfy:'利息寶返佣'


	},
	common: {
		httpErr:'服務器鏈接失敗！',
		httpErr2:'地址不存在',
		cancel:'返回',
		ts:'提示',
		tsTitle:'請驗證資金密碼',
		czcg : '充值成功' ,
		qxcz :[ '取消本訂單' , '我已超時' , '重新匹配服務商帳戶' ],
		djs : '充值倒數' ,
		time:[ '分' , '秒' ] ,

		cz: '儲值',
		tx: '提現',
		login: '登入',
		reg: '註冊',
		banlance: '餘額',
		in: '收入',
		confirm: '儲存',
		price: '價格',
		yj: '佣金',
		nodata: '暫無資料',
		copysuccess: '複製成功',
		copy: '複製',
		order: '訂單',
		nosame: '兩次輸入的密碼不一致!',
		set: '設定',
		payment:'支付方式',
		choose:'請選擇',
		yjdz:'預計到賬金額',
		tj:'提交',
		czTip:'正在匹配服务商收款账户，请稍等…',
		pz:'上传凭证',
		order_no:'订单号',
		sczh:'收款账户',
		shz:'审核中',
		tj2:"我已完成付款，提交凭证",
		qdz:'抢单中',
		tips:'請您在30分鐘內完成转账，完成转账后请您上传转账记录截图。如果超過時間限制，订单将自动取消',
		czsb:'充值失败',

	},
	index: {
		tel:'手機號碼',
		nameTip:'用户名只能输入英文字母、数字、下划线',
		level: '會員等級',
		czmoney: '儲值金額',
		inshow: '佣金收入展示',
		todayin: '今日收益',
		book: '現在預訂',
		rate: '佣金率',
		friend: '合作夥伴',
		username: '使用者名稱',

		pwd: '密碼',
		surepwd: '確認密碼',
		code: '邀請碼',
		tip1: '沒有帳號，請前往註冊',
		tip2: '已經有帳號？登入',
		xyz: '信用分',
		menu1: ['儲值', '提現', '提現記錄', '帳戶明細', '團隊報表', '銀行卡', '安全中心', '設定'],
		menu2: ['推薦計畫', '貴賓服務', '常見問題', '關於我們','訂單規則'],
		exit: '退出',
		nosame: '兩次輸入的密碼不一致!',
		notice: 'Booking期待與您的合作'
	},
	book: {
		ksqd: '開始搶單',
		title: '現在預訂',
		tdnum: '今日預訂限額',
		tdfinishnum: '已經預訂'
	},
	address: {
		title: '收貨地址',
		name: '真實姓名',
		phone: '電話號碼',
		area: '地區',
		address: '詳細地址'
	},
	bankcard: {
		title: '我的銀行卡',
		tip: '尊敬的用戶，為了保護您的資金，請不要輸入您的銀行卡密碼。我們的員工不會要求您輸入銀行卡的PIN碼。',
		digtitle: '新增銀行卡',
		name: '姓名',
		bank_name: '銀行名稱',
		bank_code: 'IFSC代碼',
		cardnum: '銀行卡號',
		tel: '手機號碼',
		paypwd: '付款密碼'
	},
	conclusion: {
		title: '提現',
		tip: '提款通常在24小時內完成。',
		num: '輸入金額',
		submittip: '提現已提交，等待處理',
		freeze: '凍結金額'
	},
	caiwu: {
		title: '財務明細',
		typeList: ['系統', '充值', '交易', '返傭', '強制交易', '推廣返傭', '下級交易返傭', '提現申請扣除餘額', '提現審核失敗退回餘額']
	},
	team: {
		title: '團隊報表',
		all: '全部資料',
		num: '團隊人數',
		yj: '團隊訂單佣金',
		one: '一級',
		two: '二級',
		three: '三級',
		four: '四級' ,
		five: '五級' ,
		qb: '全部' ,
		jt: '今天' ,
		zt: '昨天' ,
		sz: '本週' ,
		bl: '比例' ,
		tdsy: '團隊收益' ,
		yhxx: '使用者資訊' ,
		xq: '詳情' ,
		gb: '關閉' ,
		yhm: '使用者名稱' ,
		cz: '儲值' ,
		sy: '收益' ,
		fy: "返傭" ,
		yq: '邀請好友' ,
		yq2: '邀請好友贏佣金' ,
		tgm: '推廣碼' ,
		link: '複製邀請鏈接',
		startDate:'开始日期',
		endDate:'结束日期',
		by:'本月'

	},
	withdrawrecord: {
		title: '提現記錄',
		table: ['數量', '時間', '狀態'],
		stausList: ['待處理', '通過', '拒絕']
	},
	order: {
		title: '歷史記錄',
		pj: '評估',
		finish: '完成',
		statusList: ['全部', '已完成', '凍結中', '未處理']
	},
	recharge: {
		noaddress: '未設定地址',
		num: '輸入數量'
	},
	safecenter: {
		title: '安全中心',
		loginpwd: '登入密碼',
		payPwd: '交易密碼',
		old: '舊密碼',
		new: '新密碼',
		sure: '確認新密碼',
		menu: ['收貨地址', '登入密碼', '支付密碼']
	},
	language: {
		title: '設定語言'
	},
	article: {
		title1: '推薦計畫',
		content1: 'ashop平台成員可以使用推薦邀請碼邀請其他人加入您的團隊，反過來，作為上線成員，您可以獲得下線團隊成員的收入比例。收入講透過你在平台中的帳戶或團隊報告中直接反還給您。請注意：說有代理商以及其下線會員都會從平台中獲取一定比例的收入和獎金。 ashop版權所有。',
		title2: '貴賓服務',
		content2: '等級介紹：平臺共劃分為6個等級，等級越高當日可匹配訂單數量越多，所獲取訂單傭金越高，在ashop獲得的尊貴權益越等多。<br/><br/>尊敬的VIP1，充值金額$300USD每日擁有30個匹配訂單每個訂單完成可獲得0.5%傭金獎勵<br/><br/>尊敬的VIP2，充值金額$1000USD每日擁有60個匹配訂單每個訂單完成可獲得0.7%傭金獎勵<br/><br/>尊敬的VIP3，充值金額$3000USD每日擁有90個匹配訂單每個訂單完成可獲得0.9%傭金獎勵<br/><br/>尊敬的VIP4，充值金額$10000USD每日擁有120個匹配訂單每個訂單完成可獲得1.2%傭金獎勵<br/><br/>尊敬的VIP5，$充值金額30000USD每日擁有150個匹配訂單每個訂單完成可獲得1.5%傭金獎勵<br/><br/>尊敬的VIP6，$充值金額50000USD每日擁有180個匹配訂單每個訂單完成可獲得1.8%傭金獎勵<br/><br/>2.操作步驟：<br/><br/>點擊“開始搶單”按鈕根據提示完成。<br/><br/>完成單個訂單任務後系統會自動結算所完成的訂單傭金到餘額',
		title3: '常見問題',
		content3: '重要提示：嚴禁任何惡意洗錢行為。一經發現，平台將凍結以下帳戶並採取相應的法律行動。存款每筆存款必須轉入支援服務以協助補充帳戶。將資金轉平台支援團隊提供的帳戶後，提供成功轉入帳戶的確認資訊。在出售的情況下，為確保立即收到押金，請確保您轉帳的人的姓名以及您轉帳的金額與所述一致。如果儲值過程中有任何未解決的問題，請聯絡客戶支援。由於資訊量較大，請在完成交易前仔細檢查您在本平台的帳戶資訊。平台可能會定期變更帳戶資訊。如果您有任何疑問，請聯絡平台線上客服以獲得進一步協助。提款提款前，請在平台上關聯您的提款地址。從螢幕提取資金並輸入您要提取的金額和提取密碼。提現時間為20分鐘，提現時間以平台安排為準。平台的營運時間表。上午10:00至晚上10:00，用戶可在平台工作時間內獲得旅遊套裝折扣。 （註：如需協助，請聯絡我們的客服）',
		title4: '關於我們',
		content4: 'ashop是美國本土的一個時尚品牌，2011年成立在舊金山，主要銷售衣服、包包、鞋子，品牌定位就是做高品質的經典基本款。這個永續時尚品牌堅持Exceptionalquality（卓越品質）、Ethicalfactories（道德工廠）、RadicalTransparency（極度透明）的營運概念，在歐美市場上發展的很好。 <br/>ashop非常注重產品質量，採用優質面料，精緻剪裁，力求打造高性價比的經典產品，而不是一昧的追求款式創新和產量，產品少而精。 ashop這個牌子的衣服以舒適為主，都設計的很有特色，而且款式不會太複雜，比較好搭配。衣服款式經典耐穿、設計簡約品質好、又是明星同款，最重要的是，價格上還挺良心。雖然看起來都是一年四季的基本款，配色設計似乎也毫無新意可說，但簡約經典的款式確實可能讓它成為你衣櫃裡「未來十年都不會過時」的一件衣服。 <br/>ashop所倡導的「高品質」「基本款」的結合既能保證較長的穿著壽命又可以百搭，完美地闡釋了慢消費哲學。這家品牌的服飾經常斷貨，打折的時候比較少，至多7折，不過斷貨原因也不見得​​是多麼搶手，很多產品過上一陣子又會補貨重上。 ashop品牌已經走上世界大舞台',
		title5: '訂單規則',
		content5: "重要提示：嚴禁任何惡意洗錢行為。 一經發現，平臺將凍結以下帳戶並採取相應的法律行動。 存款每筆存款必須轉入支持服務以協助補充帳戶。 將資金轉平臺支持團隊提供的帳戶後，提供成功轉入帳戶的確認資訊。 請確保您轉帳的人的姓名以及您轉帳的金額與所述一致。 如果充值過程中有任何未解决的問題，請聯系客服支持。 由於信息量較大，請在完成交易前仔細檢查您在本平臺的帳戶資訊。 平臺可能會定期更改帳戶資訊。 如果您有任何疑問，請聯系平臺線上客服以獲得進一步幫助。 提款前，請在平臺上關聯您的銀行帳號。 從荧幕選取資金並輸入您要選取的金額和選取密碼。<br/><br/>平臺運行規則：<br/><br/>1.本平臺採用人工智慧化系統，更方便快捷的服務商家和搶單用戶；<br/><br/>2.本平臺服務全球與ashop合作的20萬+的電商商家，商家為提高商品曝光度而將商品在本平臺展示出售，搶單用戶在完成訂單後，商戶會立即返還訂單金額以及傭金，平臺為搶單用戶隨機匹配商戶訂單，傭金由訂單大小以及VIP等級有關：<br/><br/>訂單數量與VIP等級相關，VIP等級越高，訂單數量越多，利潤越高；<br/>3.平臺系統會根據用戶帳戶餘額判定VIP等級並為搶單用戶昇/降等級：<br/><br/>4.搶單用戶每天僅限一次完成相關VIP等級的訂單數量，搶單用戶完成相應VIP等級的訂單數量後方可提現； 完成當日訂單後，如果用戶還想繼續賺取傭金，可以選擇提高VIP等級或者等待第二天的系統重置訂單：<br/><br/>5.平臺為激勵搶單用戶，設定有幸運訂單，幸運訂單隨機匹配；<br/><br/>6.隱私政策，ashop平臺遵重每一位搶單用戶的隱私，更注重保護搶單用戶隱私； 以上ashop平臺享有最終解釋權，敬請用戶遵守規則<br/>  "
	},
	help: {
		title: "客戶服務",
		text1: '如果您有什麼問題或需要協助，請聯絡線上客服為您解決',
		text2: '點擊我，聯絡您的專屬客服',
		text3: "常見問題解答",
		contact: '聯絡客服',
		asklist: [{
				value: 1,
				title: '如何註冊會員?',
				content: '採用邀請制註冊新會員，為了確保已註冊會員的收益，防止過度註冊降低收益，新會員只能在現有會員邀請下註冊。'
			},
			{
				value: 2,
				title: '如何儲值，有哪些支付方式?',
				content: '本系統對多個國家開放，我們提供國際化的支付方式,針對不同國家支持本國的貨幣。'
			},
			{
				value: 3,
				title: '我如何才能投資並獲得收益?',
				content: '你可以在系統搶單，每天都能獲得穩定的自動刷單收益和利息，所有收益都可以提現到你的銀行卡。'
			},
			{
				value: 4,
				title: '購買金額是否有限制?',
				content: '有，為了讓更多會員有機會獲得收益，系統每一個帳號都有一個購買金額的最低限制。'
			},
			{
				value: 5,
				title: '操作過程中遇到問題怎麼辦?',
				content: '你隨時可以聯絡線上客服協助完成所有操作。'
			}
		]
	}
}
