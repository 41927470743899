import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from "vue-router";

const routes = [{
		path: '/',
		redirect: '/index'
	},
	{
		path: "/index",
		name: "index",
		meta: {
			title: ''
		},
		component: () => import("../views/index.vue")
	},
	{
		path: "/me",
		name: "me",
		meta: {
			title: 'Me'
		},
		component: () => import("../views/me.vue")
	},
	{
		path: "/invite",
		name: "invite",
		meta: {
			title: 'Invite'
		},
		component: () => import("../views/invite.vue")
	},
	{
		path: "/book",
		name: "book",
		meta: {
			title: 'Book'
		},
		component: () => import("../views/book.vue")
	},
	{
		path: "/article",
		name: "article",
		meta: {
			title: 'article'
		},
		component: () => import("../views/article.vue")
	},
	{
		path: "/bankcard",
		name: "bankcard",
		meta: {
			title: 'bankcard'
		},
		component: () => import("../views/bankcard.vue")
	},
	{
		path: "/conclusion",
		name: "conclusion",
		meta: {
			title: 'conclusion'
		},
		component: () => import("../views/conclusion.vue")
	},
	{
		path: "/order",
		name: "order",
		meta: {
			title: 'order'
		},
		component: () => import("../views/order.vue")
	},
	{
		path: "/recharge",
		name: "recharge",
		meta: {
			title: 'recharge'
		},
		component: () => import("../views/recharge.vue")
	},
	{
		path: "/checkRecharge",
		name: "checkRecharge",
		meta: {
			title: 'checkRecharge'
		},
		component: () => import("../views/checkRecharge.vue")
	},
	{
		path: "/record",
		name: "record",
		meta: {
			title: 'record'
		},
		component: () => import("../views/record.vue")
	},
	{
		path: "/safecenter",
		name: "safecenter",
		meta: {
			title: 'safecenter'
		},
		component: () => import("../views/safecenter.vue")
	},
	{
		path: "/withdrawRecord",
		name: "withdrawRecord",
		meta: {
			title: 'withdrawRecord'
		},
		component: () => import("../views/withdraw-record.vue")
	},
	{
		path: "/language",
		name: "language",
		meta: {
			title: 'language'
		},
		component: () => import("../views/language.vue")
	},
	{
		path: "/login",
		name: "login",
		meta: {
			title: 'login'
		},
		component: () => import("../views/login.vue")
	},
	{
		path: "/register",
		name: "register",
		meta: {
			title: 'register'
		},
		component: () => import("../views/register.vue")
	},
	{
		path: "/webview",
		name: "webview",
		meta: {
			title: 'webview'
		},
		component: () => import("../views/webview.vue")
	},
	{
		path: "/safemenu",
		name: "safemenu",
		meta: {
			title: 'safemenu'
		},
		component: () => import("../views/safemenu.vue")
	},
	{
		path: "/address",
		name: "address",
		meta: {
			title: 'Address'
		},
		component: () => import("../views/address.vue")
	},
	{
		path: "/setting",
		name: "setting",
		meta: {
			title: 'setting'
		},
		component: () => import("../views/setting.vue")
	},
	{
		path: "/team",
		name: "team",
		meta: {
			title: 'Team'
		},
		component: () => import("../views/team.vue")
	},
	{
		path: "/lixibao",
		name: "lixibao",
		meta: {
			title: 'lixibao'
		},
		component: () => import("../views/lixibao.vue")
	},
	{
		path: "/zrjl",
		name: "zrjl",
		meta: {
			title: 'zrjl'
		},
		component: () => import("../views/zrjl.vue")
	},
	{
		path: "/caiwu",
		name: "caiwu",
		meta: {
			title: 'Detail'
		},
		component: () => import("../views/caiwu.vue")
	},
	{
		path: "/help",
		name: "help",
		meta: {
			title: 'Help'
		},
		component: () => import("../views/help.vue")
	},
	{
		path: '/404',
		name: '404',
		meta: {
			title: '找不到页面'
		},
		component: () => import('../views/404.vue')
	},
	{
		path: '/403',
		name: '403',
		meta: {
			title: '没有权限'
		},
		component: () => import('../views/403.vue')
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
});

router.beforeEach((to, from, next) => {
	document.title = `ashop`;
	next();
});
export default router;
